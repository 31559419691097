
import dayjs, { Dayjs } from 'dayjs';
import { _Client } from '@/api-client';
import { SearchAllocationDetailsInput, ExaminerAllocationDetailDto, TimetableDto, ExportExaminerAllocationInput, TimeWindowEnum, AvailableStatusEnum, ExaminerAllocationStatusEnum, SendAllocationInput, CreateExaminerAllocationInput, FileResponse, WorkStatusEnum } from '@/api-client/client';
import { defineComponent, getCurrentInstance, onMounted, provide, reactive, ref, toRefs, unref } from "vue";
import moment from "moment-timezone";
import kpi from "@/views/cenralAllocation/allocationDetails/kpi.vue";
import { Modal, message } from 'ant-design-vue';
import { LeftOutlined, DownOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
import { ExportXlsx } from "@/utils/common";
import BatchEditExaminerTasks from './BatchEditExaminerTasks.vue'
import ConfirmAllocationDelete from './ConfirmAllocationDelete.vue'
import Log from './Log.vue'
import { CalendarOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";



export default defineComponent({
    components: {
        LeftOutlined,
        DownOutlined,
        ExclamationCircleOutlined,
        BatchEditExaminerTasks,
        ConfirmAllocationDelete,
        kpi,
        Log
    },

    setup() {
        const examinerList = ref<any>([]);
        const { currentRoute, push, back } = useRouter();
        const route = unref(currentRoute);
        const columns = [
            {
                title: 'Examiner Name',
                dataIndex: 'examinerName',
                key: 'examinerName',
                width: 120
            },
            {
                title: 'Examiner No.',
                dataIndex: 'examinerNo',
                key: 'examinerNo',
                width: 80
            },
            {
                title: 'Initial',
                dataIndex: 'examinerInitial',
                key: 'initial',
                width: 80
            },
            {
                title: 'Work Type',
                dataIndex: 'workType',
                key: 'workType',
                width: 80
            },
            {
                title: 'Qualification',
                dataIndex: 'examinerQualification',
                key: 'qualification',
                width: 120
            },
            {
                title: 'Time Window',
                dataIndex: 'timeWindowStr',
                key: 'timeWindow',
                width: 50
            },
            {
                title: 'Task',
                dataIndex: 'examinerAllocationTaskTypeStr',
                key: 'task',
                width: 50
            },
            {
                title: 'Task Number',
                dataIndex: 'taskNumber',
                key: 'taskNumber',
                width: 100
            },
            {
                title: 'Available Status',
                dataIndex: 'availableStatusStr',
                key: 'availableStatus',
                width: 100
            },
            {
                title: 'Allocation Status',
                dataIndex: 'allocationStatusStr',
                key: 'allocationStatus',
                width: 100
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                width: 50
            }
        ]

        const tableLoading = ref<boolean>(false);
        const saveLoading = ref<boolean>(false);
            
        const dataSource = ref<ExaminerAllocationDetailDto[]>();
        const startTimetableList = ref<TimetableDto[]>();
        const endTimetableList = ref<TimetableDto[]>();
        const selectStartTimetableList = ref<TimetableDto[]>();
        const selectEndTimetableList = ref<TimetableDto[]>();
        const cardLoading = ref<boolean>(false);
        const showBatchEditExaminerTasksModal = ref<boolean>(false)
        const showConfirmAllocationDeleteModal = ref<boolean>(false)
        const showLogModal = ref<boolean>(false)
        const showSyncAlocationResultModal = ref<boolean>(false)
        const examinerAllocationIdList = ref<string[]>()
        
        const planId: string = route.params.planId.toString();
        provide('examinerAllocationPlanId', planId)
        const workDate = route.params.workDate.toString();
        const syncAllocationResultAvailable = ref<boolean>(false)

        const selectedRowKeys = ref<string[]>([]); //// checkbox 已选中的数据
        const selectedRowKeysData = ref<ExaminerAllocationDetailDto[]>([]); //// checkbox 已选中的数据
        const searchParames = reactive<SearchAllocationDetailsInput>(SearchAllocationDetailsInput.fromJS({
            qualificationList: [],
            timeWindowList: [],
            availableStatus: undefined,
            allocationStatus: undefined,
            taskNumber: undefined,
            examinerNo: undefined,
            workType: []
        }));

        const sendPreAllocationNotification = () => {
            cardLoading.value = true;
            _Client.examDayPlanClient.sendPreAllocationNotification(new SendAllocationInput({ workDate: new Date(workDate) })).then(() => {
                message.success("send success");
                getList();
            }).finally(() => { cardLoading.value = false; })
        }
        
        const sendActualAllocationNotification = () => {
            cardLoading.value = true;
            _Client.examDayPlanClient.sendActualAllocationNotification(new SendAllocationInput({ workDate: new Date(workDate) })).then(() => {
                message.success("send success");
                getList();
            }).finally(() => { cardLoading.value = false; })
        }

        const batchDelete = () => {
            examinerAllocationIdList.value = selectedRowKeysData.value.map(item => item.examinerAllocationId).filter(id => id !== null);
            if (examinerAllocationIdList.value.length==0) {
                message.error("No Data");
                return;
            }

            showConfirmAllocationDeleteModal.value=true;
        }

        const SyncAlocationResult = () => {
            showSyncAlocationResultModal.value=true;
        }

        const SyncAlocationResulSubmit = () => {
            saveLoading.value = true;
            _Client.examDayPlanClient.syncAlocationResult(planId).then((rep) => {
                if (rep)
                {
                    message.success('Success')
                }else
                {
                    message.error("Save failed");
                }
                
                getList();
                getPlanScore();

            }).finally(() => {saveLoading.value = false; showSyncAlocationResultModal.value=false;})
        }

        const autoAllocate = async () => {
            cardLoading.value = true
            let result = await _Client.examDayPlanClient.createExaminerAllocationTask(new CreateExaminerAllocationInput({ date: new Date(workDate) }))
            if (result.isSuccessful) {
                cardLoading.value = false
                message.success("Auto-allocation Success.");
                getList();
                getPlanScore();
            }
            else {
                cardLoading.value = false
                message.error(result.message);
            }
        }

        const getLog=()=>{
            showLogModal.value=true;
        }
        const exportAllocation = () => {
            cardLoading.value = true;
            _Client.examDayPlanClient.exportAvailabilitySummary(new ExportExaminerAllocationInput({ workTime: new Date(workDate) })).then(rep => {
                let excelStream: FileResponse | null = rep;
                const date = new Date(workDate); // 创建一个日期对象
                const year = date.getFullYear(); // 获取年份
                const month = String(date.getMonth() + 1).padStart(2, '0'); // 获取月份并补零
                const day = String(date.getDate()).padStart(2, '0'); // 获取日期并补零
                ExportXlsx(excelStream, excelStream!.fileName || 'Allocation Result ' + year + month + day + '.xlsx')
            }).finally(() => { cardLoading.value = false; })
        }

        //show
        const edit = async (data: ExaminerAllocationDetailDto) => {
            examinerList.value = [];
            examinerList.value.push(
                {
                    single: true,
                    workType: data.workType,
                    examinerId: data.examinerId,
                    examinerName:data.examinerName,
                    examinerNo: data.examinerNo,
                    examinerInitial: data.examinerInitial,
                    examinerQualification: data.examinerQualification,
                    timeWindow: data.timeWindow,
                    taskNumber: data.taskNumber,
                    startTimeSlotId: data.startTimeSlotId,
                    endTimeSlotId: data.endTimeSlotId,
                    examinerAllocationTaskType: data.examinerAllocationTaskType,
                    remark: data.remark
                });

            selectStartTimetableList.value = startTimetableList.value?.filter(s => s.timeWindow == data.timeWindow).sort((a, b) => a.sort - b.sort);
            selectEndTimetableList.value = endTimetableList.value?.filter(s => s.timeWindow == data.timeWindow).sort((a, b) => a.sort - b.sort);
            showBatchEditExaminerTasksModal.value = true
        };

        const batchEdit = async () => {
            examinerList.value = [];
            var timeWindowList = [];
            selectedRowKeysData.value.forEach((data: any) => {
                examinerList.value.push(
                    {
                        workType: data.workType,
                        examinerId: data.examinerId,
                        examinerName:data.examinerName,
                        examinerNo: data.examinerNo,
                        examinerInitial: data.examinerInitial,
                        examinerQualification: data.examinerQualification,
                        timeWindow: data.timeWindow,
                        taskNumber: data.taskNumber,
                        startTimeSlotId: data.startTimeSlotId,
                        endTimeSlotId: data.endTimeSlotId,
                        examinerAllocationTaskType: data.examinerAllocationTaskType,
                        remark: data.remark
                    });
                timeWindowList.push(data.timeWindow);
            });

            selectStartTimetableList.value = startTimetableList.value?.filter(s => timeWindowList.some(timeWindow => timeWindow === s.timeWindow)).sort((a, b) => {
                const timeWindowComparison = a.timeWindow.localeCompare(b.timeWindow);
                if (timeWindowComparison !== 0) {
                    return timeWindowComparison;
                }
                return a.sort - b.sort;
            });
            selectEndTimetableList.value = endTimetableList.value?.filter(s => timeWindowList.some(timeWindow => timeWindow === s.timeWindow)).sort((a, b) => {
                const timeWindowComparison = a.timeWindow.localeCompare(b.timeWindow);
                if (timeWindowComparison !== 0) {
                    return timeWindowComparison;
                }
                return a.sort - b.sort;
            });
            showBatchEditExaminerTasksModal.value = true
        };

        //close
        const closeConfirmAllocationDelete = async (refresh: boolean = false) => {
            showConfirmAllocationDeleteModal.value = false
            if (refresh) {
                getPlanScore()
                await getList()
            }
        }

         //close
         const closeLog = async (refresh: boolean = false) => {
            showLogModal.value = false
        }

         //close
         const closeBatchEditExaminerTasks = async (refresh: boolean = false) => {
            showBatchEditExaminerTasksModal.value = false
            if (refresh) {
                getPlanScore()
                await getList()
            }
        }

        const kpiScore = ref()
        const getPlanScore = () => {
            _Client.examDayPlanClient.getPlanKpiScore(planId).then(rep => {
                kpiScore.value = rep == null ? '-' : rep
            })
        }

        const resetSearchParams = () => {
            searchParames.init(SearchAllocationDetailsInput.fromJS({
                qualificationList: [],
                timeWindowList: [],
                availableStatus: undefined,
                allocationStatus: undefined,
                taskNumber: undefined,
                examinerNo: undefined,
                workType: []
            }));

            getList();
        }

        //// 复选框筛选
        const onSelectChange = (
            keys: string[],
            selectedRows: ExaminerAllocationDetailDto[]
        ) => {
            selectedRowKeys.value = keys;
            selectedRowKeysData.value = selectedRows;
        };

        const getList = () => {
            //去除勾选
            selectedRowKeys.value = [];
            selectedRowKeysData.value = [];

            tableLoading.value = true;
            searchParames.workDate = new Date(workDate);
            _Client.examDayPlanClient.getExaminerAllocationDetailList(searchParames).then(rep => {
                dataSource.value = rep;
            }).finally(() => { tableLoading.value = false })
            getSyncAllocationResultAvailable()
        }

        const getSyncAllocationResultAvailable = () => {
            searchParames.workDate = new Date(workDate);
            _Client.examinerAllocationManualArrangementClient.getSyncAllocationResultAvailable(planId).then(rep => {
                syncAllocationResultAvailable.value = rep;
            })
        }

        const getTimetableList = () => {
            searchParames.workDate = new Date(workDate);
            _Client.examinerAllocationManualArrangementClient.getTimetableList(new Date(workDate)).then(rep => {
                startTimetableList.value = rep.filter(item => item.startDate);
                endTimetableList.value = rep.filter(item => !item.startDate);
            })
        }

        onMounted(() => {
            getTimetableList();
            getList();
            getPlanScore();
        })

        return {
            planId,
            saveLoading,
            workDate,
            syncAllocationResultAvailable,
            cardLoading,
            columns,
            tableLoading,
            timeWindowEnum: TimeWindowEnum,
            availableStatusEnum: AvailableStatusEnum,
            examinerAllocationStatusEnum: ExaminerAllocationStatusEnum,
            searchParames,
            selectedRowKeys,
            selectedRowKeysData,
            dataSource,
            startTimetableList,
            endTimetableList,
            showBatchEditExaminerTasksModal,
            showConfirmAllocationDeleteModal,
            examinerList,
            selectStartTimetableList,
            selectEndTimetableList,
            kpiScore,
            examinerAllocationIdList,
            showSyncAlocationResultModal,
            SyncAlocationResulSubmit,
            SyncAlocationResult,
            batchDelete,
            batchEdit,
            back,
            resetSearchParams,
            onSelectChange,
            sendPreAllocationNotification,
            sendActualAllocationNotification,
            autoAllocate,
            getList,
            exportAllocation,
            edit,
            closeBatchEditExaminerTasks,
            closeConfirmAllocationDelete,
            workType:WorkStatusEnum,
            closeLog,
            showLogModal,
            getLog
        }
    }
})
